export enum BookingStep {
  ACCOUNT_CREATION = "account_creation",
  ACCOUNT_SELECTION = "account_selection",
  APPT_REASON_SELECTION = "appt_reason_selection",
  APPOINTMENT_CONFIRMATION = "appt_confirmation",
  DOUBLE_BOOKING_GUARD = "double_booking_guard",
  LOADING = "loading",
  // Legacy events stole some good namespace here... the LOCATION_SELECTION event is actually
  // fired when a get-care page loads, because a list of locations appears on that screen.
  // At a later time, we added location selection to web scheduling directly... so I added
  // "PURE_LOCATION_SELECTION" to help keep the analytics separate.
  LOCATION_SELECTION = "location_selection",
  PURE_LOCATION_SELECTION = "pure_location_selection",
  ORIGINAL_APPOINTMENT_CONFIRMATION = "original_appt_confirmation",
  PATIENT_SELECTION = "patient_selection",
  REASON_SELECTION = "reason_selection",
  TIME_SELECTION = "time_selection",
  TIME_RESELECTION = "time_reselection", // NOT CONFIRMED YET w/ Hoda
  RESCHEDULING = "rescheduling",
  RESCHEDULING_CONFIRMATION = "rescheduling_confirmation",
}
